<template>
  <div class="notice container">
    <section>
      <article class="listWrap" v-if="noticeList.length > 0">
        <ul>
          <router-link :to="{ name: 'noticeDetail', query: { id: item._id } }" tag="li" class="flexB" v-for="(item, i) in noticeList" :key="i">
            <div>
              <span class="category">{{ item.category == "EVENT" ? "이벤트" : item.category == "NOTICE" ? "공지사항" : "" }}</span>
              <p>
                {{ item.title }}
              </p>
              <span>{{ moment(item.createdAt).format("YYYY-MM-DD") }}</span>
            </div>
            <span class="material-icons-round"> arrow_forward_ios </span>
          </router-link>
        </ul>
        <button class="moreBtn" @click="handleMore" v-show="noticeList.length < total">더보기<span class="material-icons"> add </span></button>
      </article>
      <div class="nodata" v-else><p>공지사항이 없습니다.</p></div>
    </section>
  </div>
</template>
<script>
import moment from "moment";
import { fetchNoticeList } from "@/api/index";

export default {
  data() {
    return {
      moment: moment,
      noticeList: [],
      page: 1,
      total: 0,
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", 4);
    this.$store.dispatch("SET_MENU_VISIBLE", true);
    this.$store.dispatch("SET_NAVBAR_VISIBLE", true);
    this.$store.dispatch("SET_PAGE_NAME", "공지사항");
  },
  mounted() {
    this.getNotice();
  },
  methods: {
    openAlert(msg) {
      this.$alert(msg, {
        confirmButtonText: "확인",
        center: true,
      });
    },
    handleMore() {
      this.page++;
      this.getNotice();
    },
    getNotice() {
      let params = {
        page: this.page,
      };
      fetchNoticeList(params).then((res) => {
        if (res.data.status == 200) {
          let tempList = this.noticeList;
          this.total = res.data.total;
          this.noticeList = tempList.concat(res.data.data);
          if (this.noticeList.length > 0) {
            localStorage.setItem("noticeCount", this.noticeList[0].id);
          }
          tempList = [];
        } else {
          this.openAlert(res.data.message);
        }
      });
    },
  },
};
</script>
